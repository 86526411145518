// import "@fontsource/bowlby-one"
import "@fontsource-variable/baloo-2"
// import "@fontsource/paytone-one"
import "@fontsource/sigmar-one"
import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
	textStyles: {
		openingStatement: {
			fontWeight: "bold",
		},
	},
	fonts: {
		heading: `'Sigmar One', sans-serif`,
		body: `'Baloo 2 Variable', sans-serif`,
	},
	components: {
		Heading: {
			baseStyle: {
				letterSpacing: -1,
				fontWeight: 400,
			},
		},
	},
})

export default theme
