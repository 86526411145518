import { Button, Flex, Stack, Box, Center, Spacer } from "@chakra-ui/react"
import { colors } from "../../StylingConstants"
import whiteFlowersRotated from "../../Assets/white_flowers_rotatedP.jpg"
import { useState } from "react"
import { useResponsiveAttributes } from "../../Hooks"
import {
	BlurbInformation,
	Blurb,
	BigPictureBackground,
} from "../../TheriaComponents"

const serviceInformationList: BlurbInformation[] = [
	{
		name: "Maintenance",
		dotPoints: [
			"Regular ecosystem upkeep",
			"Seasonal adjustments",
			"Biodiversity and harvest tracking",
			"Feedback and education",
		],
	},
	{
		name: "Gardens for Wildlife",
		dotPoints: [
			"Creating habitats for native species",
			"Planting native flora to attract birds, insects, and small mammals",
			"Providing food sources and nesting sites",
			"Promoting ecological balance and natural pest control",
		],
	},
	{
		name: "Design and Develop",
		dotPoints: [
			"Custom designs tailored to local ecosystems",
			"Sustainable landscaping and permaculture principles",
			"Integration of edible plants and native species",
			"Creating functional and aesthetically pleasing green spaces",
		],
	},

	{
		name: "Consulting",
		dotPoints: [
			"Expert advice on sustainable gardening practices",
			"Strategic planning for large-scale projects",
			"Educational workshops and community engagement",
			"Tailored solutions for enhancing biodiversity",
		],
	},
]

export const TheriaServices = () => {
	const [activeTab, setActiveTab] = useState(0)

	const { isCollapsed } = useResponsiveAttributes()
	return (
		<BigPictureBackground image={whiteFlowersRotated} padBelowNavigationBar>
			<Flex
				minW={"100%"}
				minH={"100%"}
				p={4}
				borderRadius={"3xl"}
				flexDirection={isCollapsed ? "column" : "row"}
			>
				<Center>
					<Stack direction={"column"}>
						{serviceInformationList.map((service, index) => (
							<Button
								borderRadius={"xl"}
								key={index}
								mt={index === 0 ? 0 : "auto"}
								mb={
									index === serviceInformationList.length - 1
										? 0
										: "auto"
								}
								bgColor={
									activeTab === index
										? colors.theriaGreen
										: colors.pageBackground
								}
								color={
									activeTab === index
										? colors.pageBackground
										: colors.theriaGreen
								}
								_hover={{
									bgColor: colors.theriaGreen,
									color: colors.pageBackground,
								}}
								opacity={"90%"}
								onClick={() => {
									setActiveTab(index)
								}}
								maxWidth={"md"}
							>
								{service.name}
							</Button>
						))}
					</Stack>
				</Center>
				<Spacer />
				<Center w={"100%"}>
					<Box
						bgColor={"white"}
						borderRadius={"xl"}
						opacity={"90%"}
						p={isCollapsed ? 2 : 8}
						mt={isCollapsed ? 2 : 0}
						h="fit-content"
					>
						<Blurb
							information={serviceInformationList[activeTab]}
						/>
					</Box>
				</Center>
				<Spacer />
			</Flex>
		</BigPictureBackground>
	)
}
