import { useState, useEffect } from "react"
import { GRID_SWITCH_POINT, COLLAPSE_WIDTH } from "../StylingConstants"

export const useResponsiveAttributes = () => {
	const [isCollapsed, setIsCollapsed] = useState(
		window.innerWidth < COLLAPSE_WIDTH
	)
	const [isFlexible, setIsFlexible] = useState(
		window.innerWidth < GRID_SWITCH_POINT
	)

	useEffect(() => {
		function handleResize() {
			setIsCollapsed(window.innerWidth < COLLAPSE_WIDTH)
			setIsFlexible(window.innerWidth < GRID_SWITCH_POINT)
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return { isCollapsed, isFlexible }
}
